<template>
  <div class="resource-centre">
    <template v-if="isMobile">
      <div class="resource-centre-head">
        <div class="resource-centre-head-txt">
          <div class="title">iLaw&nbsp;·&nbsp;资源中心</div>
          <div class="desc">
            <div>丰富的行业资讯和动态、合规课程与</div>
            <div>直播，帮助企业走在合规前沿</div>
          </div>
        </div>
        <img src="../../images/newHome/resourceCentre-banner.png" alt="" class="banner" />
      </div>
    </template>
    <template v-else>
      <div class="resource-centre-head">
        <div class="resource-centre-head-txt">
          <div class="title">iLaw&nbsp;·&nbsp;资源中心</div>
          <div class="desc">
            丰富的行业资讯和动态、合规课程与直播，帮助企业走在合规前沿
          </div>
        </div>
        <img src="../../images/newHome/resourceCentre/banner.png" alt="" class="banner" />
      </div>
    </template>
    <div class="resource-centre-container">
      <div class="resource-centre-container-left">
        <div class="resource-centre-container-left-selected">
          <div class="selected-head">
            <div class="selected-head-left">
              <img src="../../images/newHome/resourceCentre/icon.png" alt="" class="icon" />合规精选课程
            </div>
          </div>
          <div class="selected-card" v-loading="selectedLoading">
            <div class="selected-card-item" v-for="(item, index) of selectedList" :key="index" @click="goToVideo(item)"
              style="cursor: pointer">
              <img :src="item.imgUrl" alt="" class="picture" />
              <div class="describe">
                <el-tooltip v-if="item.type === 1" class="item" effect="dark" :content="item.name" placement="top">
                  <div class="title">{{ item.name }}</div>
                </el-tooltip>
                <div class="title" v-else>{{ item.name }}</div>
                <div class="tag" v-if="item.type === 1">{{ "ESG合规" }}</div>
                <div class="tag" v-if="item.type === 2">{{ "企业合规" }}</div>
                <div class="tag" v-if="item.type === 3">{{ "数据合规" }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="resource-centre-container-left-article">
          <template v-if="isMobile">
            <MobileArticleList :articleList="articleList" title="实务文章"></MobileArticleList>
          </template>

          <template v-if="!isMobile">
            <div class="article-head">
              <div class="article-head-left">实务文章</div>
              <div class="article-head-right" @click="articleToView(1)">
                查看更多
                <img src="../../images/newHome/resourceCentre/arrow.png" alt="" class="arrow" />
              </div>
            </div>
            <div class="article-card">
              <div class="article-card-item" v-for="item of articleList" :key="item.id">
                <template v-if="item.jumpLink">
                  <div class="article-card-item-left">
                    <div class="iframeBG" v-html="ImgFun(item.displayCover, 'bg')"></div>
                  </div>
                  <a class="article-card-item-right" style="cursor: pointer" :href="item.jumpLink" target="_blank">
                    <h2 class="article-card-item-right-head">
                      {{ item.title }}
                    </h2>
                    <div class="article-card-item-right-bottom">
                      <div class="time">
                        {{
                          item.articleUpdateTime &&
                          item.articleUpdateTime.split(" ")[0]
                        }}
                      </div>
                    </div>
                  </a>
                </template>
                <template v-if="!item.jumpLink">
                  <div class="article-card-item-left">
                    <div class="iframeBG" v-html="ImgFun(item.displayCover, 'bg')"></div>
                  </div>
                  <div class="article-card-item-right" style="cursor: pointer" @click="openLink(item.link)">
                    <h2 class="article-card-item-right-head">
                      {{ item.title }}
                    </h2>
                    <div class="article-card-item-right-bottom">
                      <div class="time">
                        {{
                          item.articleUpdateTime &&
                          item.articleUpdateTime.split(" ")[0]
                        }}
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="resource-centre-container-right">
        <LiveBroadcast :videList="videList" title="iLaw直播" v-if="isMobile"></LiveBroadcast>
        <template v-else>
          <div class="live-broadcast">
            <div class="live-broadcast-head">
              <div class="live-broadcast-head-left">iLaw直播</div>
              <div class="live-broadcast-head-right" @click="articleToView(2)">
                <span>查看更多</span>
                <img src="../../images/newHome/resourceCentre/arrow.png" alt="" class="arrow" />
              </div>
            </div>
            <div class="live-broadcast-card">
              <div class="live-broadcast-card-item" v-for="(item, index) of videList" :key="index"
                @click="openLink(item.link)">
                <div class="live-broadcast-card-item-left">
                  <div class="live-broadcast" v-html="supplement(item.cover, 'bg')"></div>
                </div>
                <div class="live-broadcast-card-item-right" style="cursor: pointer">
                  <el-tooltip class="item" effect="dark" :content="item.title" placement="top">
                    <h2 class="title">{{ item.title }}</h2>
                  </el-tooltip>
                  <div class="time">
                    {{
                      item.articleUpdateTime &&
                      item.articleUpdateTime.split(" ")[0]
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="seek-advice-from">
          <img src="../../images/newHome/resourceCentre/bj.png" alt="" class="bj" />
          <div class="qr-code">
            <img src="../../images/newHome/resourceCentre/qr-code.jpeg" alt="" class="code" />
            <div class="title">扫码关注iLaw公众号</div>
            <div class="title">获取更多合规资讯</div>
          </div>
        </div>
      </div>
    </div>
    <MobileFooter v-if="isMobile"></MobileFooter>
    <template v-else>
      <footerCom></footerCom>
      <reservationCom></reservationCom>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import "./index.scss";
@import "@/pages/css/mobile-base.scss";

@media(max-width: $MobileWidth) {
  @import "./mobile-resourceCentre.scss";
}
</style>

<script>
import LiveBroadcast from "@/components/live-broadcast/index";
import MobileArticleList from "@/components/mobile-article-list/index";
import footerCom from "../../components/footerCom/index";
import reservationCom from "../../components/reservationCom/index";
import MobileFooter from "@/components/mobile-footer/index";
import api from "../../api/request";
import { mapState } from "vuex";
export default {
  metaInfo: {
    title: "iLaw合规_资源中心_合规培训_iLaw直播_合规资讯_法律直播",
    meta: [
      {
        name: "keywords",
        content:
          "数据资产入表、合规培训教育课程,合规考试,合规师,合规资讯,法律直播,iLaw直播",
      },
      {
        name: "description",
        content:
          "数据资产入表、合规培训教育课程,合规考试,合规师,合规资讯,法律直播,iLaw直播",
      },
    ],
  },
  name: "ResourceCenter",
  components: { footerCom, reservationCom, LiveBroadcast, MobileFooter, MobileArticleList },
  computed: {
    ...mapState(["isMobile"])
  },
  data() {
    return {
      selectedList: [],
      videList: [],
      selectedLoading: false,
      articleList: [],
    };
  },
  mounted() {
    this.getSelected();
    this.getArticles();
    this.getSupplement();
  },
  methods: {
    async getSelected() {
      this.selectedLoading = true;
      const params = {
        endDate: "",
        excludeCourseId: 0,
        isAll: true,
        name: "",
        orderType: 0,
        pageNum: 1,
        pageSize: 10,
        startDate: "",
        transcodingSuccess: true,
        videoCategory: 20,
      };
      const { code, msg, data } = await api.getSelected(params);
      if (code === 0) {
        this.selectedList = data.list || [];
        this.selectedList.forEach((el, index) => {
          el.type = index + 1;
        });
        console.log(this.selectedList, " this.selectedList");
        this.selectedLoading = false;
      } else {
        this.$message.error(msg);
        this.selectedLoading = false;
      }
    },
    ImgFun(url, type) {
      let styles;
      let img;
      if (type === "bg") {
        img = "imgBg";
        styles = "width:100%;height:100%;position:absolute;top:0;left:0;"
        // "width:250px;position:absolute;top:0;left:0;";
      }
      var randomId = img + url;
      window[`${img}${url}`] =
        '<img id="img" style=\'' +
        styles +
        "' src='" +
        url +
        "?" +
        "img" +
        "'/><script>window.onload = function() { parent.document.getElementById('" +
        randomId +
        "').height = 100+'%'; } <" +
        "/script>";
      var iframeStr = `<iframe id=${randomId} src="javascript:parent['${img}${url}']" frameBorder="0" scrolling="no" width="100%"></iframe>`;
      return iframeStr;
    },

    supplement(url, type) {
      let styles;
      let img;
      if (type === "bg") {
        img = "imgBg";
        styles = "width:120px;height:120px;position:absolute;top:0;left:0;";
      }
      var randomId = img + url;
      window[`${img}${url}`] =
        '<img id="img" style=\'' +
        styles +
        "' src='" +
        url +
        "?" +
        "img" +
        "'/><script>window.onload = function() { parent.document.getElementById('" +
        randomId +
        "').height = 100+'%'; } <" +
        "/script>";
      var iframeStr = `<iframe id=${randomId} src="javascript:parent['${img}${url}']" frameBorder="0" scrolling="no" width="100%"></iframe>`;
      return iframeStr;
    },
    articleToView(type) {
      const url = window.location.origin;
      window.open(`${url}/article?type=${type}`);
    },
    goToVideo(item) {
      const url = window.location.origin;
      window.open(`${url}/selectedVideo?type=${item.type}&uuid=${item.uuid}`);
    },

    async getArticles() {
      const params = {
        pageNum: 1,
        pageSize: 7,
      };
      const { code, msg, data } = await api.getArticles(params);
      if (code === 0) {
        this.articleList = data.list || [];
      } else {
        this.$message.error(msg);
      }
    },

    async getSupplement() {
      const params = {
        pageNum: 1,
        pageSize: 5,
      };
      const { code, msg, data } = await api.getSupplement(params);
      if (code === 0) {
        this.videList = data.list || [];
      } else {
        this.$message.error(msg);
      }
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    goForm() {
      const url = window.location.origin;
      window.open(`${url}/appointment`);
    },
  },
};
</script>
