<template>
  <div>
    <div class="live-broadcast-head" v-if="title">
      <div class="live-broadcast-head-left">{{ title }}</div>
      <div class="live-broadcast-head-right" style="cursor: pointer" @click="articleToView(2)">
        <span>查看更多</span>
        <img src="../../images/newHome/resourceCentre/arrow.png" alt="" class="arrow" />
      </div>
    </div>
    <div class="live-broadcast-card">
      <div class="live-broadcast-card-item" v-for="(item, index) of videList" :key="index" @click="openLink(item.link)">
        <div class="live-broadcast-card-item-left">
          <div class="live-broadcast" v-html="supplement(item.cover, 'bg')"></div>
          <div class="live-broadcast-tag">直播</div>
        </div>
        <div class="live-broadcast-card-item-right" style="cursor: pointer">
          <el-tooltip class="item" effect="dark" :content="item.title" placement="top">
            <div class="title">{{ item.title }}</div>
          </el-tooltip>
          <div class="time">
            {{
              item.articleUpdateTime && item.articleUpdateTime.split(" ")[0]
            }}
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    videList: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    }
  },
  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
    articleToView(type) {
      const url = window.location.origin;
      window.open(`${url}/article?type=${type}`);
    },
    supplement(url, type) {
      let styles;
      let img;
      if (type === "bg") {
        img = "imgBg";
        styles = "width:100%;height:100%;position:absolute;top:0;left:0;";
      }
      var randomId = img + url;
      window[`${img}${url}`] =
        '<img id="img" style=\'' +
        styles +
        "' src='" +
        url +
        "?" +
        "img" +
        "'/><script>window.onload = function() { parent.document.getElementById('" +
        randomId +
        "').height = 100+'%'; } <" +
        "/script>";
      var iframeStr = `<iframe id=${randomId} src="javascript:parent['${img}${url}']" frameBorder="0" scrolling="no" width="100%"></iframe>`;
      return iframeStr;
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/pages/css/mobile-base.scss";

.live-broadcast-head {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-left {
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(17, 25, 43, 1);
  }

  &-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(150, 154, 163, 1);

    .arrow {
      width: px-to-vw(12);
      height: px-to-vw(12);
    }
  }
}

.live-broadcast-head,
.live-broadcast-card {
  padding: 0 px-to-vw(20) !important;
  box-sizing: border-box !important;
}

.live-broadcast-card {
  display: flex;
  flex-direction: column;
  border-radius: px-to-vw(10);
  max-height: 100% !important;
  // background-color: #fafcff !important;
}

.live-broadcast-card-item {
  display: flex;
  padding: px-to-vw(20) 0;
  height: 100%;
  border-bottom: 1px solid rgba(237, 240, 245, 1);

  .live-broadcast-card-item-left {
    margin-left: 0;
    margin-right: px-to-vw(15);
    position: relative;

    .live-broadcast-tag {
      width: px-to-vw(25.89);
      height: px-to-vw(10.17);
      background-color: #FF3D00;
      color: #ffffff;
      font-size: px-to-vw(7.39);
      position: absolute;
      left: 8%;
      bottom: 10%;
      border-radius: px-to-vw(12);
    }

    .live-broadcast {
      width: px-to-vw(148);
      height: px-to-vw(95);
      flex: none;
      overflow: hidden;
      border-radius: px-to-vw(4);
    }
  }

  .live-broadcast-card-item-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    cursor: pointer;

    .title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(17, 25, 43, 1);
    }

    .item {
      font-size: px-to-vw(16.65);
    }

    .time {
      font-size: px-to-vw(13.95);
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 19.6px;
      color: rgba(150, 154, 163, 1);
    }
  }
}

::v-deep iframe {
  width: 100% !important;
  height: 100% !important;
}

.live-broadcast-head-left {
  font-size: px-to-vw(21);
  font-weight: 700;
}

.live-broadcast-head-right {
  font-size: px-to-vw(12);
}

.live-broadcast-card:last-child {
  .live-broadcast-card-item:last-child {
    border-bottom: none;
  }
}
</style>