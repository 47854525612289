<template>
  <div>
    <div class="article-head" v-if="title">
      <div class="article-head-left">{{ title }}</div>
      <div class="article-head-right" @click="articleToView(1)">
        查看更多
        <img src="../../images/newHome/resourceCentre/arrow.png" alt="" class="arrow" />
      </div>
    </div>
    <div class="article-card">
      <div class="article-card-item" v-for="item of articleList" :key="item.id">
        <template v-if="item.jumpLink">
          <div class="article-card-item-left">
            <div class="iframeBG" v-html="ImgFun(item.displayCover, 'bg')"></div>
          </div>
          <a class="article-card-item-right" style="cursor: pointer" :href="item.jumpLink" target="_blank">
            <h2 class="article-card-item-right-head">
              {{ item.title }}
            </h2>
            <div class="article-card-item-right-bottom">
              <div class="time">
                {{
                  item.articleUpdateTime &&
                  item.articleUpdateTime.split(" ")[0]
                }}
              </div>
            </div>
          </a>
        </template>
        <template v-if="!item.jumpLink">
          <div class="article-card-item-left">
            <div class="iframeBG" v-html="ImgFun(item.displayCover, 'bg')"></div>
          </div>
          <div class="article-card-item-right" style="cursor: pointer" @click="openLink(item.link)">
            <h2 class="article-card-item-right-head">
              {{ item.title }}
            </h2>
            <div class="article-card-item-right-bottom">
              <div class="time">
                {{
                  item.articleUpdateTime &&
                  item.articleUpdateTime.split(" ")[0]
                }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    articleList: {
      type: Array,
      default: () => ([]),
    },
    title: {
      type: String,
      default: "",
    }
  },
  methods: {
    articleToView(type) {
      const url = window.location.origin;
      window.open(`${url}/article?type=${type}`);
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    ImgFun(url, type) {
      let styles;
      let img;
      if (type === "bg") {
        img = "imgBg";
        styles = "width:100%;height:100%;position:absolute;top:0;left:0;"
        // "width:250px;position:absolute;top:0;left:0;";
      }
      var randomId = img + url;
      window[`${img}${url}`] =
        '<img id="img" style=\'' +
        styles +
        "' src='" +
        url +
        "?" +
        "img" +
        "'/><script>window.onload = function() { parent.document.getElementById('" +
        randomId +
        "').height = 100+'%'; } <" +
        "/script>";
      var iframeStr = `<iframe id=${randomId} src="javascript:parent['${img}${url}']" frameBorder="0" scrolling="no" width="100%"></iframe>`;
      return iframeStr;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/pages/css/mobile-base.scss";

.article-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 px-to-vw(20);
  margin-top: px-to-vw(20);

  .article-head-left {
    font-size: px-to-vw(21);
    font-family: Alimama ShuHeiTi;
    font-weight: 700;
    color: rgba(17, 25, 43, 1);
  }

  .article-head-right {
    font-size: px-to-vw(12);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(150, 154, 163, 1);

    .arrow {
      width: px-to-vw(14);
      height: px-to-vw(14);
    }
  }
}

.article-card {
  height: 100%;
  border-radius: px-to-vw(8);
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 1);

  .article-card-item {
    display: flex;
    border-bottom: 1px solid rgba(237, 240, 245, 1);
    margin: 0 px-to-vw(20);
    padding: px-to-vw(20) 0;

    .article-card-item-left {
      .iframeBG {
        width: px-to-vw(148);
        height: px-to-vw(96);

        ::v-deep iframe {
          width: 100%;
          height: 100%;
          border-radius: px-to-vw(4);
        }
      }
    }

    .article-card-item-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      margin-left: px-to-vw(15);
      cursor: default;

      .article-card-item-right-head {
        display: flex;
        flex-wrap: wrap;
        text-align: left;
        font-size: px-to-vw(14);
        font-weight: 500;
        font-family: PingFang SC;
        color: rgba(17, 25, 43, 1);
        line-height: px-to-vw(24);
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .article-card-item-right-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .time {
          font-size: px-to-vw(12);
          font-family: PingFang SC;
          font-weight: 400;
          color: rgba(150, 154, 163, 1);
        }
      }
    }
  }
}

.article-card:last-child {
  .article-card-item:last-child {
    border: none;
  }
}
</style>